<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="distributorName"
                    label="分销姓名"
                >
                    <el-input
                        v-model="queryFormModel.distributorName"
                        placeholder="请输入分销姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.statDateDay"
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="referenceId"
                />
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        excel导出
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                    fixed="left"
                />
                <el-table-column
                    label="分销商信息"
                    fixed="left"
                    width="240"
                >
                    <el-table-column
                        prop="mobile"
                        label="会员账号"
                        width="120"
                    />
                    <el-table-column
                        prop="distributorName"
                        label="分销姓名"
                        width="120"
                    />
                </el-table-column>
                <el-table-column
                    label="自身绩效"
                >
                    <el-table-column
                        prop="selfOrderCount"
                        label="本人下单订单数"
                        width="105"
                    />
                    <el-table-column
                        prop="selfOrderAmount"
                        label="本人下单订单金额"
                        width="120"
                    />
                    <el-table-column
                        prop="selfShopOrderCount"
                        label="本人店铺订单数"
                        width="105"
                    />
                    <el-table-column
                        prop="selfShopOrderAmount"
                        label="本人店铺订单金额"
                        width="120"
                    />
                    <el-table-column
                        label="本人总订单数"
                        width="105"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.selfOrderCount + scope.row.selfShopOrderCount }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="本人总订单金额"
                        width="105"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.selfOrderAmount + scope.row.selfShopOrderAmount | number }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="下属绩效"
                >
                    <el-table-column
                        prop="subOrderCount"
                        label="团队下属下单数"
                        width="105"
                    />
                    <el-table-column
                        prop="subOrderAmount"
                        label="团队下属下单金额"
                        width="120"
                    />
                    <el-table-column
                        prop="subShopOrderCount"
                        label="团队下属店铺订单数"
                        width="130"
                    />
                    <el-table-column
                        prop="subShopOrderAmount"
                        label="团队下属店铺订单金额"
                        width="145"
                    />
                    <el-table-column
                        label="团队下属总订单数"
                        width="120"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.subOrderCount + scope.row.subShopOrderCount }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="团队下属总订单金额"
                        width="130"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.subOrderAmount + scope.row.subShopOrderAmount | number }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="累计绩效"
                >
                    <el-table-column
                        prop="teamMembers"
                        label="团队总人数"
                        width="105"
                    />
                    <el-table-column
                        label="团队总订单数"
                        width="105"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.selfOrderCount + scope.row.selfShopOrderCount + scope.row.subOrderCount + scope.row.subShopOrderCount }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="团队总订单金额"
                        width="105"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.selfOrderAmount + scope.row.selfShopOrderAmount + scope.row.subOrderAmount + scope.row.subShopOrderAmount | number }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="viewChildren(scope.row)"
                            v-if="scope.row.teamMembers !== 0"
                        >
                            查看下级
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'GroupOrderStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                distributorName: '',
                statDateDay: '',
                referenceId: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.OrderStat.groupOrderStatList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        //excel导出
        exportExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let statBeginDate = null;
            let statEndDate = null;
            if (this.queryFormModel.statDateDay != null && this.queryFormModel.statDateDay.length === 2) {
                statBeginDate = this.queryFormModel.statDateDay[0];
                statEndDate = this.queryFormModel.statDateDay[1];
            }
            this.$http.href('/stat/orderStat/exportGroupOrderStatExcel', {
                ...this.queryFormModel,
                statBeginDate: statBeginDate,
                statEndDate: statEndDate,
                ids: ids,
            });
        },
        viewChildren(row) {
            this.queryFormModel.referenceId = row.id;
            this.onQuery();
        },
    },
};
</script>

<style lang="scss">
</style>
